<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ตั้งค่า
      </v-toolbar-title>
    </v-toolbar>

    <div class="mx-4 box box-2" style="min-height: 100%;">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            style="height: 100%"
        >
          <v-card
              style="height: 100%"
              class="pa-2 rounded-lg elevation-2"
              outlined
              tile
          >
            <!--            <router-link :to="{ path: '/setting/customer'}" target="_blank">-->
            <!--              <v-list-item>-->
            <!--                <v-list-item-content>-->
            <!--                  <v-list-item-title>ข้อมูลลูกค้า</v-list-item-title>-->
            <!--                </v-list-item-content>-->
            <!--              </v-list-item>-->
            <!--            </router-link>-->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Setting"
}
</script>

<style scoped>

</style>